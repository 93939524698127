import { ThriveApplication } from '@thriveglobal/thrive-web-core'
import { type ComponentProps } from 'react'

type ThriveInMemoryCacheConfig = Exclude<
    ComponentProps<typeof ThriveApplication>['apolloClientOptions'],
    undefined
>['cacheOptions']

export const apolloCacheOptions: ThriveInMemoryCacheConfig = {
    typePolicies: {
        GameQuery: {
            merge: true
        }
    }
}
